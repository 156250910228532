import { ethers } from 'ethers'
import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { useQuery } from 'react-query'
import { useAccount } from 'wagmi'
import { resolveEns } from '~/api/resolve-ens'

export default function useAddressParams() {
  const router = useRouter()
  const address = (router.query.address as string)?.toLowerCase()
  const account = useAccount()

  const fetcher = useCallback(async () => {
    if (!address) return
    if (address.endsWith('.eth')) {
      let resolvedAddress = await resolveEns(address)
      if (!resolvedAddress) {
        resolvedAddress = await ethers.providers.getDefaultProvider().resolveName(address)
      }
      return resolvedAddress || address
    }
    if (address === 'me') {
      return account.address
    }
    return address
  }, [address, account])

  const query = useQuery(['resovled-address', address], fetcher, { staleTime: Infinity })

  return query.data
}
