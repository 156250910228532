import { BigNumber } from 'ethers'
import { formatEther, parseEther } from 'ethers/lib/utils'

export function formatPrice(price: BigNumber): string {
  const isNegative = price.isNegative()
  if (price.isZero()) {
    return '0 Ξ'
  }
  if (price.abs().lt(parseEther('0.001'))) {
    return `< ${isNegative ? '-' : ''}0.001 Ξ`
  }

  const fixedLength = price.abs().gt(parseEther('0.01')) ? 3 : 4
  const p = parseFloat(parseFloat(formatEther(price.abs())).toFixed(fixedLength))
  if (p < 1000) {
    return `${isNegative ? '-' : ''}${p} Ξ`
  }
  return `${isNegative ? '-' : ''}${parseFloat((p / 1000).toFixed(2))}k Ξ`
}

export function getItemOpenSeaUrl(contract: string, tokenId: string) {
  return `https://opensea.io/assets/${contract}/${tokenId}`
}

export function buildSearchParams(params: Record<string, number | string | string[] | number[]>) {
  const searchParams = new URLSearchParams()
  for (const [key, value] of Object.entries(params)) {
    if (Array.isArray(value)) {
      for (const v of value) {
        searchParams.append(key, v.toString())
      }
    } else {
      searchParams.append(key, value.toString())
    }
  }
  return searchParams
}
