import axios from 'axios'

const RESOLVE_ENS = `
  query lookup($name: String!) {
    domains(
      where: { name: $name }
    ) {
      resolvedAddress {
        id
      }
    }
  }
`

export async function resolveEns(ens: string) {
  const res = await axios.post('https://api.thegraph.com/subgraphs/name/ensdomains/ens', {
    query: RESOLVE_ENS,
    variables: {
      name: ens,
    },
  })
  if (res.data.data?.domains && res.data.data?.domains.length) {
    const r = res.data.data.domains[0]
    return r.resolvedAddress.id as string
  }

  return null
}
