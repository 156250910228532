import axios from 'axios'
import pMemoize from 'p-memoize'
import { Collection } from '~/types'
import { buildSearchParams } from '~/utils'
const API_KEY = '82445212-0dac-53ef-9dcd-08082754b727'

const client = axios.create({
  baseURL: 'https://api.reservoir.tools',
  headers: {
    'x-api-key': API_KEY,
  },
})

export interface TokenItem {
  contract: string
  tokenId: string
  name: string
  image: string
  collection: {
    name: string
    image: string
    slug: string
  }
}

export async function batchGetTokens(
  tokens: {
    contractAddress: string
    tokenId: string
  }[],
) {
  const resp = await client.get<{ tokens: TokenItem[] }>('/tokens/v4', {
    params: buildSearchParams({
      tokens: tokens.map((t) => `${t.contractAddress}:${t.tokenId}`),
      limit: 50,
    }),
  })
  return resp.data.tokens
}

export const batchGetCollections = pMemoize(async function (contracts: string[]) {
  const { data } = await client.get<{
    collections: Collection[]
  }>('/collections/v4', {
    params: {
      contract: contracts,
    },
  })
  return data.collections
})

export interface ReserviorHoldingCollection {
  collection: {
    slug: string
    name: string
    image: string
    primaryContract: string
    floorAskPrice: number
    volume: {
      '1day': number
      '7day': number
      '30day': number
      allTime: number
    }
    rank:{
      '1day': number
      '7day': number
      '30day': number
      allTime: number
    }
  }
  ownership: {
    tokenCount: string
  }
}

export interface ReserviorHoldingCollectionResponse {
  collections: ReserviorHoldingCollection[]
}

export const getOwnedCollections = async function (address: string) {
  let offset = 0
  let result = [] as ReserviorHoldingCollection[]

  let response = await client.get<ReserviorHoldingCollectionResponse>(
    `/users/${address}/collections/v2`,
    {
      params: {
        limit: 100,
      },
    },
  )
  result = [...response.data.collections]
  while (response.data.collections.length >= 100) {
    offset += 100
    response = await client.get<ReserviorHoldingCollectionResponse>(
      `/users/${address}/collections/v2`,
      {
        params: {
          offset,
          limit: 100,
        },
      },
    )
    result = [...result, ...response.data.collections]
  }
  return result
}
