import axios from 'axios'
import { ethers } from 'ethers'
import { keyBy, map, uniq } from 'lodash-es'
import pMap from 'p-map'
import { SiweMessage } from 'siwe'
import {
  FlipStats,
  Trade,
  TradeItem,
  TradeByContract,
  FlipsByContract,
  FlipsByContractWithCollection,
} from '../types'
import { batchGetCollections, batchGetTokens } from './reservoir'

export async function getTrades(
  address: string,
  filter: string,
  cursor?: string,
  limit?: number,
): Promise<TradeItem[]> {
  const res = await axios.get<{ data: Trade[] }>('/api/trades', {
    headers: {
      'content-type': 'application/json',
    },
    params: {
      address,
      filter,
      cursor,
      limit,
    },
  })
  const trades = res.data.data
  const queryItems = map(trades, (it) => ({
    contractAddress: it.nft_contract,
    tokenId: it.token_id,
  }))

  const assets = await batchGetTokens(queryItems)
  const assetsMap = keyBy(assets, (asset) => `${asset.contract.toLowerCase()}_${asset.tokenId}`)
  const tradeItems = trades.map<TradeItem>((it) => {
    const asset = assetsMap[`${it.nft_contract.toLowerCase()}_${it.token_id}`]
    let tradeType: TradeItem['tradeType']
    if (it.buyer.toLowerCase() === address.toLowerCase()) {
      tradeType =
        it.seller.toLowerCase() === '0x0000000000000000000000000000000000000000'
          ? [it.buyer, ethers.constants.AddressZero].includes(it.tx_sender)
            ? 'minted'
            : 'airdrop'
          : it.price == '0' && it.order_hash == ''
          ? 'received'
          : 'bought'
    } else {
      tradeType =
        it.buyer.toLowerCase() === '0x0000000000000000000000000000000000000000'
          ? 'burn'
          : it.price == '0' && it.order_hash == ''
          ? 'sent'
          : 'sold'
    }

    if (!asset) {
      return {
        ...it,
        name: 'Unkown',
        imageUrl: undefined,
        collectionName: 'Unknown Collection',
        tradeType,
      }
    }
    const tradeItem: TradeItem = {
      ...it,
      name: asset.name || `${asset.collection.name} #${asset.tokenId}`,
      imageUrl: asset.image,
      collectionName: asset.collection.name,
      tradeType,
    }
    return tradeItem
  })
  return tradeItems
}

export async function getTradesByContract(address: string, range = '30d') {
  const res = await axios.get<TradeByContract[]>('/api/tradesByContract', {
    headers: {
      'content-type': 'application/json',
    },
    params: {
      address,
      range,
    },
  })
  return res.data
}

export async function getFlipsByContract(
  address: string,
  offset = '0',
): Promise<FlipsByContractWithCollection[]> {
  const { data } = await axios.get<FlipsByContract[]>('/api/flipsByContract', {
    headers: { 'content-type': 'application/json' },
    params: {
      address,
      offset,
    },
  })
  if (!data) {
    return []
  }
  const contracts = uniq(data.map((it) => it.nft_contract))
  const collections = keyBy(await batchGetCollections(contracts), 'primaryContract')
  return data.map((it) => {
    return {
      ...it,
      collection: collections[it.nft_contract.toLowerCase()],
    }
  })
}

export async function getFlipStats(address: string, range: string): Promise<FlipStats> {
  const res = await axios.get<FlipStats>('/api/flipStats', {
    headers: {
      'content-type': 'application/json',
    },
    params: {
      address,
      range,
    },
  })
  return res.data
}

export async function getNFTTransferStats(address: string) {
  const res = await axios.get<{
    isDone: boolean
    data: { address: string; count: number }[]
  }>('/api/getNFTTransferStats', {
    headers: {
      'content-type': 'application/json',
    },
    params: {
      address,
    },
  })
  return res.data
}

export async function getNFTTransfers(address1: string, address2: string, limit = 6) {
  const res = await axios.get<Trade[]>('/api/getNFTTransfers', {
    headers: {
      'content-type': 'application/json',
    },
    params: {
      address1,
      address2,
      limit,
    },
  })
  return res.data
}

export async function getSocialLinks(address: string, openSeaUserName: string) {
  const { data } = await axios.get<{ twitter: string }>('/api/getSocialLinks', {
    headers: {
      'content-type': 'application/json',
    },
    params: {
      address,
      openSeaUserName,
    },
  })
  return data
}

export async function addWatch(address: string) {}

export async function signIn(message: SiweMessage, signature: string) {
  return axios.post('/api/users/login', {
    message,
    signature,
  })
}
